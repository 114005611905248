<template>
  <div class="mcia-outer-conatiner">
    <div class="mcia-inner-container">
      <mds-layout-grid>
        <mds-row>
          <mds-col>
            <div>
              <PlatformItem
                v-for="item in platformData"
                :key="item.introduction"
                :introduction="item.introduction"
                :keyPoints="item.keyPoints"
                :imageFileName="item.imageFileName"
                :flipped="item.flipped"
              />
            </div>
            <div>
              <mds-button
                class="product-key-benefits-action-btn"
                variation="primary"
                size="large"
                @click="toggle = !toggle"
              >
                Request a Demo
              </mds-button>
              <mds-modal
                v-model="toggle"
                class="mcia-get-started-form-model"
                aria-label="Default Modal"
                size="medium"
                :width="'900px'"
              >
                <get-started-form
                  form-id="get-started-form-model"
                  @exit="getStartedFormToggled"
                  @toggleNotification="getStartedToggleNotification"
                />
              </mds-modal>
            </div>
          </mds-col>
        </mds-row>
      </mds-layout-grid>
    </div>
  </div>
</template>

<script>
import SurveillanceData from "@/data/cmbsSurveillance.json";
import PlatformItem from "@/components/CreditAnalytics/CMBSSurveillance/PlatformItem.vue";
import { MdsButton } from '@mds/button';
import MdsModal from '@mds/modal';
import GetStartedForm from '@/components/GetStartedForm/GetStartedForm.vue';
import { MdsLayoutGrid, MdsRow, MdsCol } from '@mds/layout-grid';

export default {
  name: 'Platform',
  components: {
    PlatformItem,
    MdsButton,
    MdsModal,
    GetStartedForm,
    MdsLayoutGrid,
    MdsRow,
    MdsCol
  },
  data() {
    return {
      platformData: SurveillanceData?.platform,
      toggle: false,
      showNotification: false,
    };
  },
  methods:{
    getStartedFormToggled() {
      this.toggle = !this.toggle;
    },
    getStartedToggleNotification(toggle) {
      this.showNotification = toggle;
      if (this.showNotification) {
        setTimeout(() => {
          this.showNotification = false;
        }, 1000);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.product-key-benefits-action-btn {
  left: $mds-space-quarter-x;
  margin-bottom: calc(#{$mds-space-8-x} + #{$mds-space-three-quarter-x});
  margin-top: 35px;
  padding: $mds-space-1-x $mds-space-3-x;
}
</style>
